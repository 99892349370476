<template>
  <el-menu
      :default-active="activeIndexs"
      @select="menuSelect"
      class="el-menu-vertical-demo"
      background-color="#161D43"
      text-color="#8EB8BC"
      :collapse="isCollage"
      mode="horizontal"
      active-text-color="#ffffff"
  >
    <div class="headerStyle">
      <img src="../../../assets/images/aside/logo.png" alt="" style="height:38px;margin-right:30px"/>
      <el-menu-item
          ref="index"
          index="0"
          @click="goIndex"
          style="height: 100%;-webkit-app-region: no-drag;">
        <span slot="title" class="fontSize">首页</span>
      </el-menu-item>
      <el-menu-item
          index="1"
          @click="pushRouter"
          style="height: 100%;-webkit-app-region: no-drag;"
          v-if="setIsFlagssGetter == 0 || setIsFlagssGetter == 2">
        <span slot="title" class="fontSize">预售交易</span>
      </el-menu-item>
      <el-dropdown
          @command="lstHandleCommand"
          v-if="setIsFlagssGetter == 0 || setIsFlagssGetter == 1"
      >
        <el-menu-item index="2" style="height: 100%;-webkit-app-region: no-drag;">
          <span slot="title" class="fontSize">现货挂牌</span>
        </el-menu-item>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            v-for="(item, index) in lstTags"
            :key="index"
            :command="String(item.flags)">
            {{ item.routeName }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-menu-item
          v-if="setIsFlagssGetter == 0 || setIsFlagssGetter == 1"
          index="3"
          @click="lstHandleCommandBidding"
          style="height: 100%;-webkit-app-region: no-drag;"
      >
        <span slot="title" class="fontSize">现货竞价</span>
      </el-menu-item>
      <el-menu-item
          index="4"
          @click="goAccount"
          style="height: 100%;-webkit-app-region: no-drag;"
          v-if="isAssets"
      >
        <span slot="title" class="fontSize">资产中心</span>
      </el-menu-item>
      <el-menu-item
          index="7"
          @click="goGuide"
          style="height: 100%;-webkit-app-region: no-drag;"
      >
        <img class="imgStyle" src="../../../assets/images/header/xsyd.png" alt="" srcset="">
        <span slot="title" class="fontSize">新手引导</span>
      </el-menu-item>
      <el-menu-item
          index="6"
          @click="goNotice"
          style="height: 100%;-webkit-app-region: no-drag;"
      >
        <div slot="title" style="position: relative;">
          <img class="imgStyle" src="../../../assets/images/header/tz.png" alt="" srcset="">
          <span class="fontSize">通知公告</span>
          <div class="lan" v-if="lanFalg"></div>
        </div>
      </el-menu-item>
      
    </div>

    <div style="position: absolute; top: 0; right: 0; display: flex; height: 60px">
      <el-dropdown @command="handleCommand1">
        <el-menu-item
            index="5"
            style="height: 100%;-webkit-app-region: no-drag;"
        >
          <div>
            <span slot="title" class="fontSize">Hi,{{ firmName }}</span>
            <span class="tx">
              <img src="../../../assets/images/header/tx.png" alt="" srcset="">
            </span>
          </div>
          
        </el-menu-item>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="0">账户&安全</el-dropdown-item>
          <el-dropdown-item command="1">身份认证</el-dropdown-item>
          <el-dropdown-item command="2" v-if="isUserShow && lst_flag">员工管理</el-dropdown-item>
          <el-dropdown-item command="3">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      

      <div v-if="isPcClient" style="margin-left:20px;">
        <Titlebtn type="min"/>
        <Titlebtn type="max"/>
        <Titlebtn type="close"/>
      </div>
    </div>
    <!-- 风险协议 -->
    <div id="dialog1">
      <el-dialog
          :visible.sync="dialogVisible"
          width="1100px"
          :close-on-click-modal="false"
          :show-close="false"
          :close-on-press-escape="false"
          style="min-width: 1000px"
      >
        <UserProtocolDY/>
        <div class="btns">
          <el-button @click="enter" :disabled="enterDisabled" type="primary">
            {{ enterName }}
          </el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 购销弹窗 -->
      <el-dialog
          :close-on-click-modal="false"
          :visible.sync="dialogVisible1"
          width="30%"
      >
        <div>您好，您即将进行预售交易模式认证，请谨慎操作！</div>
        <div>
          <div class="d2_title">邀请码:</div>
          <el-input v-model="inviteInput" placeholder="请输邀请码"></el-input>
        </div>
        <div class="d2_btns">
          <el-button
              @click="inviteEnter"
              type="primary"
              style="margin-left: 15px"
              :disabled="isDisabled1"
          >确认
          </el-button
          >
          <el-button @click="cancel1">取消</el-button>
        </div>
      </el-dialog>
    <!-- 挂牌/竞价弹窗 -->
    <div id="dialog3">
      <el-dialog :visible.sync="dialogVisible2" width="30%">
        <div>您好，您即将进行现货{{ lstName }}模式，请确认！</div>
        <div class="d2_btns">
          <el-button
              @click="lstEnter"
              type="primary"
              style="margin-left: 15px"
              :disabled="isDisabled"
          >确认</el-button>
          <el-button @click="cancel3">取消</el-button>
        </div>
      </el-dialog>
    </div>
    <examinationPaper ref="examinationPaper" @enterInto="goPagess"/>
    <el-dialog id="el_dig3" :visible.sync="identityFlag" width="40%">
      <identityModules @cancel="cancelDia_3" @popupEnter="popupEnter"/>
    </el-dialog>
  </el-menu>
</template>

<script>
import identityModules from "@/components/identityModule/identityModules";
import UserProtocolDY from "@/components/reglogin/register/UserProtocolDY";
import examinationPaper from "./children/examinationPaper";
import {mapGetters, mapActions, mapState} from "vuex";
import Titlebtn from "./Titlebtn.vue";


export default {
  components: {
    UserProtocolDY,
    examinationPaper,
    Titlebtn,
    identityModules
  },
  data() {
    return {
      firmName: '',
      auditType: {
        auditType: ""
      },
      BDcertifiy: "未认证",
      identityFlag: false,
      lanFalg: false,
      lst_flag: window.g.LST_FLAG,
      valFlags: null, // 挂牌选择标识
      marketFlag: 0, // 1 竞价 2 挂牌 3 购销
      enterDisabled: true,
      enterName: "确认",
      marketList: [],
      isDisabled1: false,
      isDisabled: false,
      gxFlagss: true,
      bidFlagss: 0,
      bidFlag: false,
      dialogVisible2: false,
      inviteInput: "",
      lstName: "挂牌",
      isAssets: true,
      dialogVisible1: false,
      dialogVisible: false,
      logoUrl: "",
      headerDate: "",
      isUserShow: false,
      timeInterval2: null,
      timeInterval1: null,
      userMes: "",
      atPrenterIndex: "",
      activeIndexs: "",
      asideData: [],
      // isPcClient: window.g.IS_PC_CLIENT,
      isPcClient: false,
      userCenter: [
        {
          name: "账户&安全",
          path: "/userOrSafety",
          icon: require("../../../assets/images/aside/user.png"),
          iconSelect: ""
        },
        {
          name: "身份认证",
          path: "/personalEnterpriseAuth",
          icon: require("../../../assets/images/aside/user.png"),
          iconSelect: ""
        },
        {
          name: "员工管理",
          path: "/personalCenter/staffAccountMgt",
          icon: require("../../../assets/images/aside/user.png"),
          iconSelect: ""
        },
        {
          name: "退出登录",
          path: "/userCenter1",
          icon: require("../../../assets/images/aside/user.png"),
          iconSelect: ""
        }
        // {
        //   name: '修改密码',
        //   path: '/accountSafe',
        //   icon: require('../../../assets/images/aside/mima.png'),
        //   iconSelect: ''
        // },
        // {
        //   name: '我的地址',
        //   path: '/address',
        //   icon: require('../../../assets/images/aside/dizhi.png'),
        //   iconSelect: ''
        // }
      ],
      posotion: [
        {
          name: "我的持仓",
          path: "/myPosition",
          icon: "home"
        },
        {
          name: "自提物流",
          path: "/logisticsBill",
          icon: "flag"
        },
        {
          name: "现货入库操作",
          path: "/warehousingApply",
          icon: "claim"
        }
      ],
      AssetCenter: [
        {
          name: "资产状况",
          path: "/conditionOfAssets"
        }
      ],
      BaseMarkets: [
        {accName: "预售交易账户", account: "预售交易账户", marketId: "28"}
        // { accName: "挂牌竞价账户", account: "挂牌竞价账户", marketId: "18" },
      ],
      lstTags: [
        {routeName: "现货销售", route: "/ResourceDocuments", flags: "0"},
        {routeName: "现货采购", route: "/purchaseDocument", flags: "1"},
        // {routeName: "产品预售", route: "/productionSell", flags: "2"}
      ]
    };
  },
  created() {
    localStorage.setItem("bidFlagss", 0);
    var arr = this.$store.state.search.firmList;
    if (arr.length == 0) {
      this.isAssets = false;
    }
    this.userMes = this.$store.state.common.sessionInfo;
    this.asideData = window.a.application;
  },
  computed: {
    ...mapGetters([
      "sessionInfoGetter",
      "userTypeGetter",
      "setIsFlagssGetter",
      "gxLeaveForFlagGetter",
      "heartbeatFlagGetter"
    ]),
    isCollage() {
      return this.$store.state.search.isCollapse;
    }
  },
  watch: {
    gxLeaveForFlagGetter(val) {
      if (this.gxLeaveForFlagGetter) {
        this.pushRouter();
        this.$store.commit("setGxLeaveForFlag", false);
      }
    },
    $route: {
      handler(val) {
        var name = JSON.parse(localStorage.getItem("setHeaderText")).name;
        if (val.path == "/notices") {
          this.activeIndexs = "6";
        } else if (val.path == "/HomePage1") {
          this.activeIndexs = "0";
        } else if (
            val.path == "/userOrSafety" ||
            val.path == "/personalEnterpriseAuth" ||
            val.path == "/personalCenter/staffAccountMgt"
        ) {
          this.activeIndexs = "5";
        } else if (val.path == "/conditionOfAssets") {
          this.activeIndexs = "4";
        } else if (val.path == "/iframeController") {
          if (name == "挂牌") {
            this.activeIndexs = "2";
          } else if (name == "预售交易") {
            this.activeIndexs = "1";
          } else if (name == "竞价") {
            this.activeIndexs = "3";
          }
        } else if (val.path == "/iframeModule") {
          if (name == "挂牌") {
            this.activeIndexs = "2";
          } else if (name == "预售交易") {
            this.activeIndexs = "1";
          } else if (name == "竞价") {
            this.activeIndexs = "3";
          }
        } else if(val.path === "/moduleXH") {
          this.activeIndexs = "2";
        } else if(val.path === "/moduleBidding") {
          this.activeIndexs = "3";
        } else if(val.path === "/moduleYSJY") {
          this.activeIndexs = "1";
        }
      },
      immediate: true
    },
    heartbeatFlagGetter() {
      if (this.heartbeatFlagGetter) {
        this.lanFalg = true;
      }
    }
  },
  mounted() {
    this.firmName = sessionStorage.getItem("userName");
    console.log(this.$store.state.common.sessionInfo, 'this.$store.state.common.sessionInfo')
    // this.getAgreementEnter();
    if (localStorage.getItem("equipmentFlag_PC")) {
      if (Number(localStorage.getItem("equipmentFlag_PC")) === 1) {
        // pc 客户端
        this.isPcClient = true;
      } else {
        // pc web端
        this.isPcClient = false;
      }
    }
    this.searchFirmId();

    this.searchMarketState();
    this.getIdentificationStatus();
    var that = this;
    // this.timeInterval1 = setInterval(
    //     () => {
    //       if (this.userMes.isLogin == "true") {
    //         this.detectionLogin();
    //       } else {
    //         clearInterval(this.timeInterval1);
    //       }
    //     },
    //     window.g.DETECTIONLOGIN_TIME ? window.g.DETECTIONLOGIN_TIME : 3000
    // );
  },
  beforeDestroy() {
    window.clearInterval(this.timeInterval1);
    window.clearInterval(this.timeInterval2);
  },
  methods: {
    cancelDia_3() {
      this.identityFlag = false;
    },
    goGuide() {
      this.$router.push("/guide");
    },
    goNewPage() {
      this.$EL_MESSAGE("暂未开放");
      this.$refs.index.handleClick();
      console.log(this.$refs.index);
    },
    goPagess() {
      var that = this;
      if (this.marketFlag == 3) {
        // 购销

        // 判断是否开通市场
        var arr1 = this.$store.state.search.firmList;
        // if (arr1.length > 0 && arr1[0].marketId == 28) {
        if (arr1.length > 0) {
          // 已开通市场
          // 判断是否通过协议
          this.getAgreementEnter();
        } else {
          // 未开通市场
          // 答题通过,打开开通市场弹窗
          this.isDisabled1 = false;
          this.dialogVisible1 = true;
        }

        this.activeIndexs = "";
      } else if (this.marketFlag === 1) {
        // 竞价
        this.lstName = "竞价";
        var arr1 = this.$store.state.search.firmList;
        for (var a = 0; a < arr1.length; a++) {
          console.log(arr1[a]);
          if (arr1[a].marketId == 18) {
            that.bidFlag = true;
            // return
          }
        }

        if (that.bidFlag) {
          that.getIdentificationStatusBidding(
              "/mall",
              "竞价"
          );
          that.activeIndexs = "3";
        } else {
          localStorage.setItem("latFLag_w", 3);
          this.dialogVisible2 = true;
        }
      } else if (this.marketFlag === 2) {
        // 挂牌
        this.lstName = "挂牌";
        var lstFlag = false;
        var arr1 = this.$store.state.search.firmList;
        for (var a = 0; a < arr1.length; a++) {
          if (arr1[a].marketId == 18) {
            lstFlag = true;
          }
        }
        if (this.valFlags == 0) {
          if (lstFlag) {
            this.activeIndexs = "2";
            this.getIdentificationStatus2(
                "/ResourceDocuments",
                "挂牌"
            );
          } else {
            localStorage.setItem("latFLag_w", 0);
            this.dialogVisible2 = true;
          }
        } else if (this.valFlags == 1) {
          if (lstFlag) {
            this.activeIndexs = "2";
            this.getIdentificationStatus2(
                "/purchaseDocument",
                "挂牌"
            );
          } else {
            localStorage.setItem("latFLag_w", 1);
            this.dialogVisible2 = true;
          }
        } else if (this.valFlags == 2) {
          if (lstFlag) {
            this.activeIndexs = "2";
            this.getIdentificationStatus2(
                "/productionSell",
                "挂牌"
            );
          } else {
            localStorage.setItem("latFLag_w", 2);
            this.dialogVisible2 = true;
          }
        }
      }
    },
    // 协议阅读时间
    agreementOpen() {
      var that = this;
      var time = window.g.AGREEMENT_TIME;
      var interval = setInterval(() => {
        time = Number(time) - 1000;
        if (time <= 0) {
          that.enterName = "确认";
          clearInterval(interval);
          that.enterDisabled = false;
        } else {
          that.enterDisabled = true;
          that.enterName = `确认(${Number(time) / 1000}s)`;
        }
      }, 1000);
    },
    // 查询市场开通状态
    searchMarketState() {
      http.postFront(protocolFwd.param_marketState).then(response => {
        var {message, value, code} = response.data;
        if (Number(code) === 0) {
          this.marketList = value;
          localStorage.setItem("marketList_w", JSON.stringify(value));
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    cancel1() {
      this.activeIndexs = 0;
      this.inviteInput = "";
      this.$router.push("/HomePage1");
      this.dialogVisible1 = false;
      console.log(this.activeIndexs, 1);
    },
    cancel3() {
      this.$router.push("/HomePage1");
      this.dialogVisible2 = false;
      this.activeIndexs = 0;
      console.log(this.activeIndexs, 2);
    },
    // 市场绑定(挂牌/竞价)
    lstEnter() {
      sessionStorage.setItem("marketId_w", 18);
      var that = this;
      this.isDisabled = true;
      // protocol.param_dredgeBazaar.param.userId = this.userMes.userId;
      // protocol.param_dredgeBazaar.param.marketId = 18;
      // protocol.param_dredgeBazaar.param.lnvitationCode = null;
      // protocol.param_dredgeBazaar.param.lnvitationCodeMustFillIn = false;
      http.postFrontMarket(protocol.param_dredgeBazaar).then(response => {
        var {message, code, value} = response.data;
        if (Number(code) === 0) {
          setTimeout(() => {
            that.isDisabled = false;
          }, 1500);
          this.searchFirmId();
          this.$EL_MESSAGE("成功");
          this.dialogVisible2 = false;
          if (localStorage.getItem("latFLag_w") == 0) {
            this.activeIndexs = 2;
            this.getIdentificationStatus2(
                "/ResourceDocuments",
                "挂牌"
            );
          } else if (localStorage.getItem("latFLag_w") == 1) {
            this.activeIndexs = 2;
            this.getIdentificationStatus2(
                "/purchaseDocument",
                "挂牌"
            );
          } else if (localStorage.getItem("latFLag_w") == 2) {
            this.activeIndexs = 2;
            this.getIdentificationStatus2(
                "/productionSell",
                "挂牌"
            );
          } else if (localStorage.getItem("latFLag_w") == 3) {
            this.activeIndexs = "3";
            this.getIdentificationStatusBidding(
                "/mall",
                "竞价"
            );
          }
          localStorage.setItem("atPresentFirmId", value);
        } else {
          that.isDisabled = false;
          this.searchFirmId();
          this.$EL_MESSAGE(message);
        }
      });
    },
    // 市场绑定(购销)
    inviteEnter() {
      sessionStorage.setItem("marketId_w", 28);
      var that = this;
      this.isDisabled1 = true;
      if (this.inviteInput == "") {
        this.$EL_MESSAGE("请填写邀请码");
        this.isDisabled1 = false;
        return;
      }
      // protocol.param_dredgeBazaars.param.userId = this.userMes.userId;
      // protocol.param_dredgeBazaars.param.marketId = 28;
      protocol.param_dredgeBazaars.param.lnvitationCode = this.inviteInput.replace(
          /\s*/g,
          ""
      );
      protocol.param_dredgeBazaars.param.lnvitationCodeMustFillIn = true;
      http.postFrontMarket(protocol.param_dredgeBazaars).then(response => {
        var {message, code, value} = response.data;
        if (Number(code) === 0) {
          setTimeout(() => {
            that.isDisabled1 = false;
          }, 1500);
          // 成功,检测是否通过协议
          this.getAgreementEnter();

          this.searchFirmId();
          this.$EL_MESSAGE("成功");
          this.dialogVisible1 = false;
          this.inviteInput = "";
          localStorage.setItem("atPresentFirmId", value);
          this.activeIndexs = "1";
          // this.gxPush();
        } else {
          that.isDisabled1 = false;
          this.searchFirmId();
          this.$EL_MESSAGE(message);
        }
      });
    },
    // 认证状态查询
    getIdentificationStatus2(url, name) {
      http
          .postFront(protocolFwd.param_findBaseUserAuditByUserId)
          .then(response => {
            var arr = this.$store.state.search.firmList;
            var firmId = 0;
            for (let i = 0; i < arr.length; i++) {
              if (arr[i].marketId == 18) {
                firmId = arr[i].firmId;
                localStorage.setItem("atPresentFirmId", firmId);
              }
            }
            var items = {
              carousel: 168,
              icon: "det.png",
              iconSelect: "det.png",
              marketId: 28,
              name: name,
              notice: 167,
              url: url
            };
            this.$store.commit("setHeaderText", items);
            localStorage.setItem("setHeaderText", JSON.stringify(items));
            this.$router.push("/moduleXH");
          });
    },
    getIdentificationStatusBidding(url, name) {
      http
        .postFront(protocolFwd.param_findBaseUserAuditByUserId)
        .then(response => {
          var arr = this.$store.state.search.firmList;
          var firmId = 0;
          for (let i = 0; i < arr.length; i++) {
            if (arr[i].marketId == 18) {
              firmId = arr[i].firmId;
              localStorage.setItem("atPresentFirmId", firmId);
            }
          }
          var items = {
            carousel: 168,
            icon: "det.png",
            iconSelect: "det.png",
            marketId: 28,
            name: name,
            notice: 167,
            url: url
          };
          this.$store.commit("setHeaderText", items);
          localStorage.setItem("setHeaderText", JSON.stringify(items));
          this.$router.push("/moduleBidding");
          // const { code, message, value } = response.data;
          // console.log(value, "认证信息");
          // if (Number(code) === 0) {
          //   if (value == null) {
          //     this.$EL_MESSAGE("暂无交易权限，请先企业认证");
          //     this.$router.push("/personalEnterpriseAuth");
          //   } else {
          //     // 认证类型（O:组织认证 P:个人认证）
          //     if (value.auditType == "O") {
          //       if (value.auditStatus == 1) {
          //         var arr = this.$store.state.search.firmList;
          //         var firmId = 0;
          //         for (let i = 0; i < arr.length; i++) {
          //           if (arr[i].marketId == 18) {
          //             firmId = arr[i].firmId;
          //             localStorage.setItem("atPresentFirmId", firmId);
          //           }
          //         }
          //         var items = {
          //           carousel: 168,
          //           icon: "det.png",
          //           iconSelect: "det.png",
          //           marketId: 28,
          //           name: name,
          //           notice: 167,
          //           url: url
          //         };
          //         this.$store.commit("setHeaderText", items);
          //         localStorage.setItem("setHeaderText", JSON.stringify(items));
          //         this.$router.push("/iframeModule");
          //       } else {
          //       }
          //     } else {
          //       protocol.param_getBaseUserBindByUserId.param.userId = this.userMes.userId;
          //       http
          //         .postFront(protocol.param_getBaseUserBindByUserId)
          //         .then(response => {
          //           var { message, value, code } = response.data;
          //           if (Number(code) === 0) {
          //             if (value) {
          //               var arr = this.$store.state.search.firmList;
          //               var firmId = 0;
          //               for (let i = 0; i < arr.length; i++) {
          //                 if (arr[i].marketId == 18) {
          //                   firmId = arr[i].firmId;
          //                   localStorage.setItem("atPresentFirmId", firmId);
          //                 }
          //               }
          //               var items = {
          //                 carousel: 168,
          //                 icon: "det.png",
          //                 iconSelect: "det.png",
          //                 marketId: 28,
          //                 name: name,
          //                 notice: 167,
          //                 url: url
          //               };
          //               this.$store.commit("setHeaderText", items);
          //               localStorage.setItem(
          //                 "setHeaderText",
          //                 JSON.stringify(items)
          //               );
          //               this.$router.push("/iframeModule");
          //             } else {
          //               this.$EL_MESSAGE("暂无交易权限，请先企业认证");
          //               this.$router.push("/personalEnterpriseAuth");
          //             }
          //           }
          //         });

          //       // this.$EL_MESSAGE('暂无交易权限，请先企业认证')
          //       // this.$router.push('/personalEnterpriseAuth')
          //     }
          //   }
          // } else {
          //   this.$EL_MESSAGE(message);
          // }
        });
    },
    lstHandleCommandBidding() {
      if (!window.g.LST_FLAG) {
        this.$EL_MESSAGE("暂未开放");
        return;
      }
      this.marketFlag = 1;
      var that = this;
      this.searchFirmId();
      this.activeIndexs = "";
      // 查询是否确认协议(进入新手引导)
      http.postFront(protocol.param_agreementEnter).then(response => {
        var {code, message, value} = response.data;
        if (Number(code) === 0) {
          if (value.userId != null) {
            // 协议通过,进入竞价市场
            this.lstName = "竞价";
            var arr1 = this.$store.state.search.firmList;
            for (var a = 0; a < arr1.length; a++) {
              if (arr1[a].marketId == 18) {
                that.bidFlag = true;
                // return
              }
            }

            if (that.bidFlag) {
              // 已开通竞价市场,直接进入
              that.getIdentificationStatusBidding(
                  "/mall",
                  "竞价"
              );
              that.activeIndexs = "3";
            } else {
              // 未开通竞价市场,进入新手引导页面
              this.$router.push("/guide");
            }
          } else {
            // 未通过,进入新手引导
            this.$router.push("/guide");
          }
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    personalEnterpriseAuthPush() {
      // 查询认证类型
      http.postFront(protocol.param_queryCertificationType).then(response => {
        var {code, message, value} = response.data;
        if (Number(code) === 0) {
          this.auditType = value;
          if (value && value.auditType) {
            if (value.auditType === "P") {
              // 个人,查询百度认证
              this.getBDcertifiy1();
              // this.$router.push("/personalEnterpriseAuth");
            } else if (value.auditType === "O") {
              // 企业,查询实名认证
              this.queryAuditByUserId();
              // this.$router.push("/personalEnterpriseAuth");
            }
          } else {
            // 未选择认证类型
            this.$router.push("/certificationTypes");
          }
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    handleCommand1(val) {
      this.leaveIframe();
      if (val == 0) {
        this.$router.push("/userOrSafety");
      } else if (val == 1) {
        // 身份认证
        this.personalEnterpriseAuthPush();
        // this.$router.push("/personalEnterpriseAuth");
      } else if (val == 2) {
        this.$router.push("/personalCenter/staffAccountMgt");
      } else if (val == 3) {
        this.logOut();
      }
    },
    lstHandleCommand(val) {
      if (!window.g.LST_FLAG) {
        this.$EL_MESSAGE("暂未开放");
        return;
      }
      this.valFlags = val;
      this.marketFlag = 2;
      this.searchFirmId();
      // 查询是否通过协议
      http.postFront(protocol.param_agreementEnter).then(response => {
        var {code, message, value} = response.data;
        if (Number(code) === 0) {
          if (value.userId != null) {
            // 协议通过,对应挂牌市场
            for (let i = 0; i < this.marketList.length; i++) {
              if (this.marketList[i].marketId == 18) {
                if (this.marketList[i].openFlag == "已开通") {
                  this.lstName = "挂牌";
                  var lstFlag = false;
                  var arr1 = this.$store.state.search.firmList;
                  for (var a = 0; a < arr1.length; a++) {
                    // 是否开通挂牌竞价市场
                    if (arr1[a].marketId == 18) {
                      // 已开通
                      lstFlag = true;
                    }
                  }
                  if (val == 0) {
                    if (lstFlag) {
                      // 已开通挂牌竞价市场,直接进入
                      this.activeIndexs = "2";
                      this.getIdentificationStatus2(
                          "/ResourceDocuments",
                          "挂牌"
                      );
                    } else {
                      // 未开通,进入新手引导
                      this.$router.push("/guide");
                    }
                  } else if (val == 1) {
                    if (lstFlag) {
                      this.activeIndexs = "2";
                      this.getIdentificationStatus2(
                          "/purchaseDocument",
                          "挂牌"
                      );
                    } else {
                      // 未开通,进入新手引导
                      this.$router.push("/guide");
                    }
                  } else if (val == 2) {
                    if (lstFlag) {
                      this.activeIndexs = "2";
                      this.getIdentificationStatus2(
                          "/productionSell",
                          "挂牌"
                      );
                    } else {
                      // 未开通,进入新手引导
                      this.$router.push("/guide");
                    }
                  }
                } else {
                  this.activeIndexs = "";
                  this.$EL_MESSAGE("暂未开放");
                }
              }
            }
          } else {
            // 未通过,进入新手引导
            this.$router.push("/guide");
          }
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    goIndex() {
      this.leaveIframe();
      this.$router.push("/HomePage1");
    },
    // 资产
    goAccount() {
      this.leaveIframe();
      this.$router.push("/conditionOfAssets");
    },
    // 公告
    goNotice() {
      this.lanFalg = false;
      this.$store.commit("setHeartbeatFlag", false);
      this.leaveIframe();
      this.$router.push("/notices");
    },
    // iframe页面通信检测
    iframeMessage() {
      protocol.param_iframeMessage.param.num = null;
      http.postFront(protocol.param_iframeMessage).then(response => {
        var {value, message, code} = response.data;
        if (Number(code) == 0) {
          if (value == 1) {
            // 认证页面
            var item = {
              name: "用户信息",
              path: "/userCenter1",
              icon: "",
              iconSelect: ""
            };
            this.$store.commit("setHeaderText", item);
            localStorage.setItem("setHeaderText", JSON.stringify(item));
            this.iframeMessage1();
            this.$router.push("/personalEnterpriseAuth");
          }
          if (value == 3) {
            // 修改资金密码
            let obj = {};
            obj.name = "资金管理";
            obj.path = "/conditionOfAssets";
            localStorage.setItem("setHeaderText", JSON.stringify(obj));
            this.$store.commit("setHeaderText", obj);
            this.iframeMessage1();
            this.$router.push("/modifyPwd");
          }
          if (value == 4) {
            // 修改登陆密码
            localStorage.setItem("userPwd", true);
            var item = {
              name: "修改密码",
              path: "/accountSafe",
              icon: "",
              iconSelect: ""
            };
            this.$store.commit("setHeaderText", item);
            localStorage.setItem("setHeaderText", JSON.stringify(item));
            this.iframeMessage1();
            this.$router.push("/accountSafe");
          }
        }
      });
    },
    iframeMessage1() {
      protocol.param_iframeMessage.param.num = 2;
      http.postFront(protocol.param_iframeMessage).then(response => {
        var {value, message, code} = response.data;
        if (Number(code) == 0) {
        }
      });
    },
    // 检测登陆状态
    detectionLogin() {
      let that = this;
      http.postFront(protocolFwd.param_islogin).then(response => {
        var {value, message, code} = response.data;
        if (code == -2 || code == -4) {
          window.clearInterval(that.timeInterval1);
          window.clearInterval(that.timeInterval2);
          this.$router.push("/login");
          this.$store.commit("setSessionInfo", "");
          this.$store.commit("setUserType", "");
          this.$store.commit("setSelectFirmApplyCommodity", "");
          localStorage.setItem("atPresentFirmId", 0);
          sessionStorage.clear();
        }
      });
    },
    menuSelect(index) {
      console.log(index, "index");
      this.atPrenterIndex = index;
    },
    goPageFund() {
      let obj = {};
      obj.name = "资金管理";
      obj.path = "/conditionOfAssets";
      // localStorage.setItem('setHeaderText', obj)
      localStorage.setItem("setHeaderText", JSON.stringify(obj));
      this.$store.commit("setHeaderText", obj);
      this.$router.push("/conditionOfAssets");
    },
    goPage(item) {
      if (item.name == "退出登录") {
        this.logOut();
      } else {
        this.$store.commit("setHeaderText", item);
        localStorage.setItem("setHeaderText", JSON.stringify(item));
        this.$router.push(item.path);
      }
    },
    logOut() {
      let that = this;
      window.clearInterval(that.timeInterval1);
      window.clearInterval(that.timeInterval2);
      this.timeInterval1 = null;
      protocol.param_logout.param.firmId = this.sessionInfoGetter.firmId;
      http.postFront(protocol.param_logout).then(response => {
        let {code, msg, values} = response.data;
        if (code == "0") {
          this.$store.commit("setSessionInfo", "");
          this.$store.commit("setUserType", "");
          this.$store.commit("setSelectFirmApplyCommodity", "");
          this.$store.commit("setFirmList", []);
          const sitetel = localStorage.getItem('sitetel')
          localStorage.clear()
          localStorage.setItem('sitetel',sitetel)
          localStorage.setItem("atPresentFirmId", 0);
          sessionStorage.clear();
          this.$router.push("/login");
        }
      });
    },
    // 弹窗确认(风险告知书)
    enter() {
      this.dialogVisible = false;
      console.log("风险协议确认");
      this.gxPush();
      this.agreementEnter();
    },
    // 确认协议接口
    agreementEnter() {
      http.postFront(protocol.param_enterAgreement).then(response => {
      });
    },
    // 协议确认流程(前)
    enter11() {
      var gxFlag = false;
      var arr1 = this.$store.state.search.firmList;
      // 查询当前市场是否开通
      for (var a = 0; a < arr1.length; a++) {
        if (arr1[a].marketId == 28) {
          gxFlag = true;
        }
      }
      this.dialogVisible = false;
      if (gxFlag) {
        // 已开通
        this.gxPush();
      } else {
        // 未开通 进入答题页面
        // this.$refs.examinationPaper.isShow();
        this.dialogVisible1 = true;
      }
    },
    // 认证状态查询
    getIdentificationStatus() {
      http
          .postFront(protocolFwd.param_findBaseUserAuditByUserId)
          .then(response => {
            const {code, message, value} = response.data;
            if (Number(code) === 0) {
              if (value == null) {
                localStorage.setItem("auditStatus_w", 2);
              } else {
                // 审核状态（0:未审核 1:已认证 2:已驳回）
                if (value.auditStatus == 1) {
                  localStorage.setItem("auditStatus_w", 1);
                } else {
                  localStorage.setItem("auditStatus_w", 2);
                }
              }
            } else {
              localStorage.getItem("auditStatus_w", 2);
            }
          })
          .catch(err => {
            // 1 通过 2 未通过
            localStorage.getItem("auditStatus_w", 2);
          });
    },
    // 查询是否确认协议
    getAgreementEnter() {
      http.postFront(protocol.param_agreementEnter).then(response => {
        var {code, message, value} = response.data;
        if (Number(code) === 0) {
          if (value.userId != null) {
            // 协议通过,进入购销市场
            this.gxPush();
          } else {
            // 未通过,打开协议弹窗
            this.dialogVisible = true;
            this.agreementOpen();
          }
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    popupEnter() {
      this.identityFlag = false;
      this.$router.push("/personalEnterpriseAuth");
    },
    // 查询百度认证
    getBDcertifiy() {
      http
          .postFront(protocol.param_getIdentificationInfo)
          .then(response => {
            const {code, message, value} = response.data;
            this.isBdRetun = true;
            if (code === "0" || code === 0) {
              if (
                  value !== null &&
                  JSON.stringify(value) !== "{}" &&
                  value !== "0"
              ) {
                // 百度已认证,进入实名认证页面
                console.log("百度已认证,进入实名认证页面");
                this.BDcertifiy = "已认证";
                this.pushRouter1();
              } else {
                // 百度未认证,打开选择认证方式弹窗
                console.log("未认证,打开选择认证方式弹窗");
                this.BDcertifiy = "未认证";
                this.$router.push("/certificationTypes");
              }
            } else {
              this.$EL_MESSAGE(message);
            }
          })
          .catch(err => {
            console.log(err);
          });
    },
    // 查询百度认证(实名认证直接进入用)
    getBDcertifiy1() {
      http
          .postFront(protocol.param_getIdentificationInfo)
          .then(response => {
            const {code, message, value} = response.data;
            this.isBdRetun = true;
            if (code === "0" || code === 0) {
              if (
                  value !== null &&
                  JSON.stringify(value) !== "{}" &&
                  value !== "0"
              ) {
                // 百度已认证,进入实名认证页面
                console.log("百度已认证,进入实名认证页面");
                this.BDcertifiy = "已认证";
                this.$router.push("/personalEnterpriseAuth");
              } else {
                // 百度未认证,打开选择认证方式弹窗
                console.log("未认证,打开选择认证方式弹窗");
                this.BDcertifiy = "未认证";
                this.$router.push("/certificationTypes");
              }
            } else {
              this.$EL_MESSAGE(message);
            }
          })
          .catch(err => {
            console.log(err);
          });
    },
    // 购销按钮点击(进入新手引导)
    pushRouter() {
      // 查询是否通过协议
      http.postFront(protocol.param_agreementEnter).then(response => {
        var {code, message, value} = response.data;
        if (Number(code) === 0) {
          if (value.userId != null) {
            // 协议通过,进入购销市场
            this.gxPush();
          } else {
            // 未通过,进入新手引导页面
            this.$router.push("/guide");
          }
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    // 实名认证查询(不带流程用)
    queryAuditByUserId() {
      http
          .postFront(protocolFwd.param_findBaseUserAuditByUserId)
          .then(response => {
            const {code, message, value} = response.data;
            if (Number(code) === 0) {
              if (value == null) {
                // 实名认证未通过,打开认证方式选择弹窗
                // 判断是个人还是企业
                if (this.auditType) {
                  if (this.auditType.auditType === "P") {
                    // 个人
                    this.$router.push("/personalEnterpriseAuth");
                  } else {
                    // 企业
                    this.$router.push("/certificationTypes");
                  }
                }
              } else {
                this.$router.push("/personalEnterpriseAuth");
              }
            } else {
              this.$router.push("/personalEnterpriseAuth");
            }
          });
    },
    // 购销跳转
    pushRouter1() {
      http
          .postFront(protocolFwd.param_findBaseUserAuditByUserId)
          .then(response => {
            const {code, message, value} = response.data;
            if (Number(code) === 0) {
              if (value == null) {
                // this.$EL_MESSAGE("请先进行实名认证");
                // 实名认证未通过,打开认证方式选择弹窗
                // 判断是个人还是企业
                if (this.auditType) {
                  if (this.auditType.auditType === "P") {
                    // 个人
                    this.$router.push("/personalEnterpriseAuth");
                  } else {
                    // 企业
                    this.$router.push("/certificationTypes");
                  }
                }
              } else {
                // 审核状态（0:未审核 1:已认证 2:已驳回）
                if (value.auditStatus == 1) {
                  if (value.auditType == "P") {
                    localStorage.setItem("name_w", value.name);
                  } else if (value.auditType == "O") {
                    localStorage.setItem("name_w", value.legalPersonName);
                  }
                  // 认证通过,进入开通市场流程
                  this.marketFlag = 3;
                  var that = this;
                  for (let i = 0; i < this.marketList.length; i++) {
                    if (this.marketList[i].marketId == 28) {
                      if (this.marketList[i].openFlag == "已开通") {
                        if (!JSON.parse(localStorage.getItem("examination_w"))) {
                          // 进入答题
                          this.$refs.examinationPaper.isShow();
                        } else {
                          // 无需答题
                          // 进入协议弹窗
                          // that.goPagess();

                          // 判断是否开通购销市场
                          var arr1 = this.$store.state.search.firmList;
                          // if (arr1.length > 0 && arr1[0].marketId == 28) {
                          if (arr1.length > 0) {
                            // 已开通
                            // 判断是否通过协议
                            this.getAgreementEnter();
                          } else {
                            // 未开通,打开开通市场弹窗
                            this.isDisabled1 = false;
                            this.dialogVisible1 = true;
                          }
                        }
                      } else {
                        this.activeIndexs = "";
                        this.$EL_MESSAGE("暂未开放");
                      }
                    }
                  }
                } else if (value.auditStatus == 0) {
                  this.$EL_MESSAGE("请等待管理员审核");
                  this.$router.push("/personalEnterpriseAuth");
                } else if (value.auditStatus == 2) {
                  this.$EL_MESSAGE("'认证被驳回，请重新认证'");
                  this.$router.push("/personalEnterpriseAuth");
                }
              }
            } else {
              this.$EL_MESSAGE("请先进行实名认证");
              this.$router.push("/personalEnterpriseAuth");
            }
          });
      // .catch(err => {
      //   this.$EL_MESSAGE("请先进行实名认证");
      //   this.$router.push("/personalEnterpriseAuth");
      // });
    },
    gxPush() {
      this.activeIndexs = "1";
      var arr = this.$store.state.search.firmList;
      var firmId = 0;
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].marketId == 28) {
          firmId = arr[i].firmId;
          localStorage.setItem("atPresentFirmId", firmId);
        }
      }
      // alert(process.env.NODE_ENV === 'production')
      var item = {
        carousel: 168,
        icon: "det.png",
        iconSelect: "det.png",
        marketId: 28,
        name: "预售交易",
        notice: 167,
        url: "./static/dist_dy/index.html"
      };
      localStorage.setItem("symbolIds", 0);
      this.$store.commit("setHeaderText", item);
      localStorage.setItem("setHeaderText", JSON.stringify(item));
      // this.$router.push("/iframeModule");
      this.$router.push("/moduleYSJY");
    },
    searchFirmId() {
      protocolFwd.param_firmIdSearch.param.userId = this.userMes.userId;
      http.postFront(protocolFwd.param_firmIdSearch).then(response => {
        var {message, value, code} = response.data;
        if (Number(code) === 0) {
          this.$store.commit("setFirmList", value);
          localStorage.setItem("setFirmList_w", JSON.stringify(value));
          if (value.length != 0) {
            this.isAssets = true;
            if (value[0].marketId == 18) {
              this.bidFlagss = 1;
              this.$store.commit("setIsFlagss", 1);
              localStorage.setItem("bidFlagss", 1);
            } else if (value[0].marketId == 28) {
              this.bidFlagss = 2;
              this.$store.commit("setIsFlagss", 2);
              localStorage.setItem("bidFlagss", 2);
            }
          } else {
            this.$store.commit("setIsFlagss", 0);
            this.$store.commit("setIsFlagss", 0);
            this.isAssets = false;
          }
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.headerStyle{
  display: flex;
  align-items: center;
  height: 60px;
}
.tx{
  display: inline-block;
  width: 36px;
  // height: 36px;
  border-radius: 50%;
  // background-color: #FFFFFF;
  margin-left: 20px;
  img{
    width: 100%;
    height: 100%;
  }
}
.imgStyle{
  width: 15px;
  padding-right: 8px;
}
/deep/ .el-menu-item{
  font-family: Source Han Sans SC;
  font-weight: 400;
  font-size: 18px;
  color: #DCDCDC !important;
  padding: 0 33px;
}
/deep/ .el-menu-item.is-active{
  color: #4774FF !important;
  font-weight: bold;
}
.el-menu-item.is-active::after{
  content: '';
  display: block;
  width: 40px;
  height: 4px;
  background: #4774FF;
  border-radius: 2px;
  margin: 0 auto;
  margin-top: -9px;
}
/deep/ #el_dig3 .el-dialog {
  overflow-y: auto;
  max-height: 95%;
}

/deep/ #el_dig3 .el-dialog__body {
  padding: 0 !important;
}

/deep/ .el-dialog__wrapper {
  overflow: inherit !important;
}

.el-dropdown-menu {
  min-width: 109px !important;
}

/deep/ #dialog1 .el-dialog {
  height: 80%;
  min-width: 1000px !important;
  overflow: hidden;
  overflow-y: auto;
}

/deep/ .el-dialog::-webkit-scrollbar {
  width: 4px;
}

/deep/ .el-dialog::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}

/deep/ .el-dialog::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
}

/deep/ :focus {
  outline: 0;
}

// /deep/ .el-button {
//   background-color: #b8ddf0;
//   border-color: #0193de;
// }
.el-menu {
  // height: 100vh;
  border: none;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  // width: 136px;
  // min-height: 400px;
  -webkit-app-region: drag;
}

.fontSize {
  font-size: 16px !important;
}

.btn {
  position: fixed;
  bottom: 9px;
  left: 9px;
}

.baseAccBtn {
  color: rgb(142, 184, 188);
  border-bottom-color: transparent;
  background-color: rgb(23, 35, 59);
  border: none !important;
  // height: 100%;
}

.baseAccBtn:hover {
  background-color: #121c2f;
}

.el-button {
  background-color: none !important;
}

/deep/ .el-submenu__title {
  height: 100%;
  font-size: 16px;
}

/deep/ .el-menu.el-menu--popup.el-menu--popup-bottom-start {
  background-color: #fff !important;
}

.d2_title {
  margin: 10px 0;
}

.d2_btns {
  margin-top: 10px;
  display: flex;
  flex-direction: row-reverse;
}

.btns {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.lan {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: red;
  top: 22px;
  right: -1px;
  position: absolute;
}
</style>
