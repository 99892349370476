<template>
  <div class="capacity">
    <el-card class="box-card">
      <div class="myApp">
        <div class="title">更多功能</div>
        <div class="appList">
          <div class="appItem" v-for="(item, index) in appList" :key="index">
            <el-button type="primary" class="appItems" @click="goApp(item)">
              <div class="text">{{item.name}}</div>
            </el-button>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return{
      appList: [
        {
          name: '认证系统',
          path: '/personalCenter/enterpriseAuth'
        },
        {
          name: '修改密码',
          path: '/personalCenter/accountSafe'
        },
        {
          name: '资金信息',
          path: '/capitalCenter/info'
        },
        {
          name: '我要充值',
          path: '/capitalCenter/deposit'
        },
        {
          name: '我要提现',
          path: '/capitalCenter/withdraw'
        },
        {
          name: '银行签约',
          path: '/capitalCenter/sign'
        },
        {
          name: '银行签约信息',
          path: '/capitalCenter/signList'
        },
        {
          name: '设置资金密码',
          path: '/capitalCenter/setPwd'
        },
        {
          name: '我的持仓',
          path: '/sellerCenter/sales/myPosition'
        },
        {
          name: '现货入库',
          path: '/sellerCenter/resource/warehousingApply'
        },
        {
          name: '自提查询',
          path: '/sellerCenter/logistics/selfBill'
        },
        {
          name: '物流查询',
          path: '/sellerCenter/logistics/logisticsBill'
        },
        {
          name: '地址管理',
          path: '/mall/user/address'
        }
      ],
      userMes: null
    }
  },
  created() {
    this.userMes = this.$store.state.common.sessionInfo
  },
  methods: {
    goApp(item) {
      console.log(item)
      this.$router.push(item.path)
    }
  }
}
</script>

<style scoped lang="scss">
.capacity {
margin: 0 auto;
  padding-top: 30px;
  .box-card {
    height: 262px;
    padding-bottom: 20px;
    .myApp {
      .title {
        font-size: 20px;
      }
      .appList {
        display: flex;
        flex-wrap: wrap;
        .appItem {
          padding-top: 10px;
          .appItems {     
            width: 170px;
            height: 60px;
            border-radius: 12px;
            margin-right: 20px;
            .text {
              overflow: hidden;    
              text-overflow:ellipsis;    
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
}
</style>