<template>
  <div class="titlebtn" @click="click">
    <span v-if="type === 'min'" :style="style">━</span>
    <span v-else-if="type === 'max'" :style="style">▢</span>
    <span v-else-if="type === 'close'" :style="style" class="title-text">
      ╳
    </span>
  </div>
</template>

<script>
const style = {
  // min: {
  //   'font-size': '18px',
  // },
  // max: {
  //   'font-size': '20px',
  // },
  // close: {
  //   'font-size': '18px',
  // }
};
export default {
  name: "Titlebtn",
  props: {
    type: {
      default: "",
      type: String
    }
  },
  computed: {
    style: function() {
      return style[this.type];
    }
    // iconfontClass: function() {
    //   switch (this.type) {
    //     case 'min':
    //       return 'iconfont icon-zuixiaohua5'
    //     case 'max':
    //       return 'iconfont icon-zuidahua1'
    //     case 'close':
    //       return 'iconfont icon-guanbi'
    //     default:
    //       return ''
    //   }
    // }
  },
  methods: {
    click: function() {
      // if (this.type === 'close') {
      //   this.$confirm('要退出程序吗？', '提示', {
      //     confirmButtonText: '确定',
      //     cancelButtonText: '取消',
      //     confirmButtonClass: 'color-white',
      //     type: 'warning'
      //   })
      //     .then(() => {
      //       ipc.send(this.type)
      //     })
      //     .catch(() => {})
      // } else {
      //   ipc.send(this.type)
      // }
    }
  }
};
</script>

<style lang="scss" scoped>
.titlebtn {
  position: relative;
  display: inline-block;
  margin-left: 15px;
  width: 24px;
  -webkit-app-region: no-drag;
  color: #dddddd;
  cursor: pointer;
  font-size: 32px;
}
.titlebtn :hover {
  color: #ffffff;
  font-weight: bold;
}
.color-white {
  color: white;
  span {
    color: white;
  }
}
</style>
