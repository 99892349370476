<template>
  <el-container style="" sticky-container>
    <el-header v-sticky style="width: 100%;overflow: hidden;">
      <common-aside/>
    </el-header>
    <el-main class="" v-if="isIfremeController">
<!--      <iframeController/>-->
    </el-main>
    <el-main  class="main" v-else>
      <router-view/>
    </el-main>
  </el-container>
</template>
<script>
import CommonAside from './component/CommonAside'
import CommonHeader from './component/CommonHeader'
import Capacity from './component/capacity'
import iframeController from './component/iframeController/iframeController'
import {mapState} from 'vuex'
import hostMap from "@/hostMap";
export default {
  components: {
    CommonAside,
    CommonHeader,
    Capacity,
    iframeController
  },
  data() {
    return {
      moduleUrl: hostMap("//localhost:3000")
    }
  },
  computed: {
    ...mapState({
      isIfremeController: state => state.search.isIfremeController
    })
  },
  mounted() {
    localStorage.setItem('userPwd', false)
    this.iframeMessage1()
    this.userMes = this.$store.state.common.sessionInfo
    let that = this;
    setTimeout(
        () =>
            (window.onresize = () => {
              that.$refs.banner.calcBannerLeft();
              // that.$refs.commodityChart.resize();
            }),
        200
    );
  },
  created() {
    // console.log(this.$store.state.common.sessionInfo, '用户信息')
    if (this.$store.state.common.sessionInfo.isLogin == 'false') {
      this.$router.push('/login')
    }
  },
  beforeDestroy() {
    window.onresize = null;
  },
  methods: {
    iframeMessage1() {
      protocol.param_iframeMessage.param.num = 2
      http.postFront(protocol.param_iframeMessage).then(response => {
        var {value, message, code} = response.data
        if (Number(code) == 0) {

        }
      })
    },
    // 检测登陆状态
    detectionLogin() {
      http.postFront(protocolFwd.param_islogin).then(response => {
        var {value, message, code} = response.data
        if (Number(code) === 0) {

        }
      })
    }
  },
};
</script>
<style scoped lang="scss">
/deep/ .el-header {
  background-color: #161D43;
  padding-right: 0;
}

.indexPage {
  // width: 1300px;
  width: 100%;
  margin: 0 auto;
}
.main {
  background: #F2F3F8;
  /*height:calc(100vh - 60px);*/
  overflow: hidden;
  padding: 0;
  /*background: #fff;*/
}
</style>
