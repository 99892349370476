<template>
  <div class="iframeModule">
    <iframe id="iframe" name="ifrmname" frameborder="0" :src="false"></iframe>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

// window.addEventListener('message',function(e){

//     alert(e);

// },false);
export default {
  props: {
    urlElements: {
      type: String,
      default() {
        return "urlun";
      }
    }
  },
  data() {
    return {
      userMes: null,
      srcs: ""
    };
  },
  computed: {
    ...mapGetters(["iframeStateGetter"]),
    ...mapState({
      src: state => state.search.headerText.url,
      marketId: state => state.search.headerText.marketId,
      iframeUrl: state => state.search.headerText.url
    })
  },
  watch: {
    iframeStateGetter() {
      console.log("状态变动1");
      // document.getElementById("iframe").src="javascript:false";
      // document.getElementById("iframe").contentWindow.closeIframe();
    },
    src(val) {
      if (this.iframeUrl.indexOf(this.urlElements) != -1) {
        if (this.srcs.indexOf("undefined") != -1) {
          this.srcs = localStorage.getItem("iframeUrl");
        } else {
          // this.searchFirmId();
          this.srcs =
            JSON.parse(localStorage.getItem("setHeaderText")).url +
            `?firmId=${localStorage.getItem("atPresentFirmId")}&sessionStr=${
              this.userMes.sessionStr
            }&tel=${this.userMes.tel}&userId=${
              this.userMes.userId
            }&symbolId=${localStorage.getItem("symbolIds")}&firmCode=1`;
          localStorage.setItem("iframeUrl", this.srcs);
        }
      }
    },
    marketId() {
      if (this.iframeUrl.indexOf(this.urlElements) != -1) {
        if (this.srcs.indexOf("undefined") != -1) {
          this.srcs = localStorage.getItem("iframeUrl");
        } else {
          // this.searchFirmId();
          this.srcs =
            JSON.parse(localStorage.getItem("setHeaderText")).url +
            `?firmId=${localStorage.getItem("atPresentFirmId")}&sessionStr=${
              this.userMes.sessionStr
            }&tel=${this.userMes.tel}&userId=${
              this.userMes.userId
            }&symbolId=${localStorage.getItem("symbolIds")}&firmCode=1`;
          localStorage.setItem("iframeUrl", this.srcs);
        }
      }
    }
  },
  created() {
    let that = this;
    this.userMes = this.$store.state.common.sessionInfo;
    // this.searchFirmId()
    // this.$store.state.search.headerText.url
    if (this.iframeUrl.indexOf(this.urlElements) != -1) {
      that.srcs =
        JSON.parse(localStorage.getItem("setHeaderText")).url +
        `?firmId=${localStorage.getItem("atPresentFirmId")}&sessionStr=${
          that.userMes.sessionStr
        }&tel=${that.userMes.tel}&userId=${
          that.userMes.userId
        }&symbolId=${localStorage.getItem("symbolIds")}&firmCode=1`;
      if (that.srcs.indexOf("undefined") != -1) {
        that.srcs = localStorage.getItem("iframeUrl");
        console.log(that.srcs, 787878);
      } else {
        localStorage.setItem("iframeUrl", that.srcs);
        console.log(that.srcs, 909090);
      }
    }

    // console.log(this.srcs, 'undefined')
    // console.log(localStorage.getItem('iframeUrl'), '取值')
  },
  beforeDestroy() {
    // this.$store.commit('setHeaderText', '')
  },
  methods: {
    GetChildValue(obj) {
      console.log("子页面传递的数据", obj);
    },
    searchFirmId() {
      protocolFwd.param_firmIdSearch.param.userId = this.userMes.userId;
      http.postFront(protocolFwd.param_firmIdSearch).then(response => {
        var { message, value, code } = response.data;
        if (Number(code) === 0) {
          for (let i = 0; i < value.length; i++) {
            if (value[i].marketId == window.a.application[i].marketId) {
              this.userMes.firmId = value[i].firmId;
              return;
            }
          }
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.iframeModule {
  height: 100%;
  iframe {
    width: 100%;
    height: 100%;
  }
}
</style>
