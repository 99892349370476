<template>
  <header>
    <div class="commonHeader">
      <el-button class="btn" type="primary" icon="el-icon-menu" size="mini" @click="isCollage"></el-button>
      <div class="title">{{name}}</div>
    </div>
    <!-- <div class="r-content">
      <el-dropdown trigger="click" size="mini" @command="handleCommand">
        <span class="el-dropdown-link">
          <img :src="userImage" alt="" class="user">
          <div>{{userName}}</div>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="a" style="text-align:center" @click="logout">退出</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div> -->
  </header>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'

export default {
  computed: {
    ...mapState({
      current: state => state.search.headerText.name
    })
  },
  watch: {
    current() {
      this.name = JSON.parse(localStorage.getItem('setHeaderText')).name
      // console.log(this.name, 'this.name')
    }
  },
  data () {
    return {
      userImage: require('../../../assets/images/244.png'),
      userName: '',
      name: ''
    }
  },
  created() {
    this.name = JSON.parse(localStorage.getItem('setHeaderText')).name
    // console.log(this.name, 'name1')
    this.userName = this.$store.state.common.sessionInfo.tel
  },
  methods: {
    isCollage() {
      this.$store.commit('setIsCollapse')
    },
    handleCommand(command) {
      // console.log(command)
      if (command == 'a') {
        this.logout()
      }
    },
    logout() {
      // console.log('tuichu')
      let firmId = this.$store.state.common.sessionInfo.firmId;
      protocol.param_logout.param.firmId = firmId;
      http.postFront(protocol.param_logout).then(response => {
        let { code, msg, values } = response.data;
        if (code == "0") {
          this.$store.commit("setSessionInfo", "");
          this.$store.commit("setUserType", "");
          this.$store.commit('setSelectFirmApplyCommodity',"")
          sessionStorage.clear();
          this.$router.push("/login");
        }
      });
    },
  }
};
</script>

<style lang="scss" scoped>
/deep/ .el-button {
  background-color: #0193DE;
  border-color: #0193DE;
}
/deep/ .el-breadcrumb__item:last-child .el-breadcrumb__inner{
  color: #fff!important;
}

.el-icon--right {
  color: #fff;
}
.el-dropdown-link {
  display: flex;
  align-items: center;
  img {
    margin-right: 5px;
  }
}
header {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff!important;
}
.user {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.l-content {
  display: flex;
  align-items: center;
  .btn {
    margin-right: 30px;
  }
}
.commonHeader {
  display: flex;
  align-items: center;
  .title {
    margin-left: 10px;
  }
}
</style>
<style lang="scss">
.el-breadcrumb__inner a, .el-breadcrumb__inner.is-link {
  color: #fff;
}

</style>