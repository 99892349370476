<!-- 控制ifrme跳转 -->
<template>
  <div id="iframeController" width="auto" style="background: #F2F3F8;height:calc(100vh - 60px);padding: 0;background:#fff;">
    <iframe-module-cell
      v-show="itemData.state"
      v-for="(itemData, index) in marketCellShowType"
      :key="index"
      :urlElements="itemData.type"
    >
    </iframe-module-cell>
  </div>
</template>
<script>
import { mapState } from "vuex";
import iframeModuleCell from "../children/iframeModuleCell.vue";
export default {
  components: {
    "iframe-module-cell": iframeModuleCell,
  },
  data() {
    return {
      marketCellShowType: [
        { type: "dist_dy", state: true },
        { type: "dist_bidding", state: false },
      ],
    };
  },
  computed: {
    ...mapState({
      firmList: (state) => state.search.firmList, // 后台返回的市场列表
      marketId: (state) => state.search.headerText.marketId, //当前marketid
      iframeUrl: (state) => state.search.headerText.url,
      headerText:  (state) => state.search.headerText,
    }),
  },
  watch: {
    firmList(val) {
      for (let i = 0; i < val.length; i++) {
        const element = val[i];
        this.marketCellShowType[`m${element.marketId}`] = false;
      }
    },
    iframeUrl(val) {
      if (val) {
        if (val.indexOf("dist_dy") != -1) {
          this.marketCellShowType = [
            { type: "dist_dy", state: true },
            { type: "dist_bidding", state: false },
          ];
        } else if (val.indexOf("dist_bidding") != -1) {
          this.marketCellShowType = [
            { type: "dist_dy", state: false },
            { type: "dist_bidding", state: true },
          ];
        }
      }
    },
    headerText(val){
      sessionStorage.setItem('c_sessionHeaderText',JSON.stringify(val))
    }
  },
  mounted() {
    if(sessionStorage.getItem('c_sessionHeaderText')){
      this.$store.commit('setHeaderText', JSON.parse(sessionStorage.getItem('c_sessionHeaderText')))
    }
    if(this.iframeUrl){
      let val = this.iframeUrl
      if (val.indexOf("dist_dy") != -1) {
          this.marketCellShowType = [
            { type: "dist_dy", state: true },
            { type: "dist_bidding", state: false },
          ];
        } else if (val.indexOf("dist_bidding") != -1) {
          this.marketCellShowType = [
            { type: "dist_dy", state: false },
            { type: "dist_bidding", state: true },
          ];
        }
    }
  }
};
</script>
<style scoped lang="scss">
// /deep/ .el-main {
//   height: 500px;
//   border: none!important;
//   margin: 0!important;
//   background-color: #F2F3F8!important;
// }
.indexPage {
    // width: 1300px;
    width: 100%;
    margin: 0 auto;
}
</style>
